import { User } from './user.model';

export enum EtatCompteRenduEnum {
  EN_COURS = 'EN_COURS',
  TERMINE = 'TERMINE',
  ENVOYE = 'ENVOYE',
}

export interface CompteRendu {
  id?: string;
  nomAnimal?: string;
  uriPiecesjointes?: string;
  dateCompteRendu?: Date;
  dateEnvoi?: Date;
  textCr?: string;
  modeleCRId?: string;
  etat?: EtatCompteRenduEnum;
  idRdv?: string;
  titre?: string;
  files?: FileCR[];
  isPdf?: boolean;
  idAnimal?: string;
  auteurId?: string;
}

export interface ModeleCR {
  id: string;
  nom: string;
  text?: string;
}

export interface FileCR {
  id?: string;
  file?: number[];
  type: FileCRTypeEnum;
  fileName: string;
  mimeType?: string;
  dateDocument: Date;
  auteur: Pick<User, 'id'>;
}

export enum FileCRTypeEnum {
  CERTIFICAT = 'CERTIFICAT',
  RETOUR_REFERE = 'RETOUR_REFERE',
  RESULTAT_ANALYSE = 'RESULTAT_ANALYSE',
  PHOTO = 'PHOTO',
  INCONNU = 'INCONNU',
}

export interface Poids {
  idAnimal: string | undefined;
  datePesee: string | null;
  poids: number;
  role?: string;
}
