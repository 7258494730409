import { Injectable } from '@angular/core';
import {
  CreationRdvInternePayloadType,
  CreationRdvPayloadType,
  FindRdvRequest,
  RdvDomicile,
  RdvInterne,
  RdvPlanning,
  RdvState,
} from '../../../../models/rdv.model';
import { TypeRdv } from '../../../../models/interaction.model';
import { RdvDomicileService } from '@core/services/consultations/rdv-domicile.service';
import { RdvInterneService } from '@core/services/consultations/rdv-interne.service';
import { Observable, of, switchMap } from 'rxjs';
import { EtatCompteRenduEnum } from '../../../../models/compte-rendu.model';

@Injectable({
  providedIn: 'root',
})
export class RdvService {
  constructor(
    private readonly rdvDomicileService: RdvDomicileService,
    private readonly rdvInterneService: RdvInterneService,
  ) {}

  public moveRdv(rdvPlanning: RdvPlanning, idConsultant: string | null, dateConsultation: Date, rdvStep?: RdvState): Observable<unknown> {
    return of(rdvPlanning.typeRdv === TypeRdv.INTERNE).pipe(
      switchMap(interne => {
        if (interne) {
          return this.rdvInterneService.moveRdvInterne(rdvPlanning.id, idConsultant, dateConsultation);
        } else {
          return this.rdvDomicileService.moveRdv(rdvPlanning.id, idConsultant, dateConsultation, rdvStep!);
        }
      }),
    );
  }

  public findFullRdv(rdvPlanning: RdvPlanning): Observable<RdvInterne | RdvDomicile | null> {
    return of(rdvPlanning.typeRdv === TypeRdv.INTERNE).pipe(
      switchMap(interne => {
        if (interne) {
          return this.rdvInterneService.accessRdv(rdvPlanning.id);
        } else {
          return this.rdvDomicileService.accessRdv(rdvPlanning.id);
        }
      }),
    );
  }

  public static rdvToRdvPlanning(rdv: RdvDomicile | RdvInterne): RdvPlanning | undefined {
    if (this.isRdvInterne(rdv)) {
      return RdvService.mapRdvInterneToRdvPlanning(rdv);
    } else if (this.isRdvDomicile(rdv)) {
      return RdvService.mapRdvDomicileToRdvPlanning(rdv);
    }

    return undefined;
  }

  public static isRdvInterne(rdv: RdvDomicile | RdvInterne | CreationRdvPayloadType | CreationRdvInternePayloadType): rdv is RdvInterne {
    return !!(rdv as RdvInterne).typeRdvInterne;
  }

  public static isRdvDomicile(rdv: RdvDomicile | RdvInterne | CreationRdvPayloadType | CreationRdvInternePayloadType): rdv is RdvDomicile {
    return (rdv as RdvDomicile).typeRdv === TypeRdv.DOMICILE;
  }

  private static mapRdvInterneToRdvPlanning(rdv: RdvInterne): RdvPlanning {
    return {
      id: rdv.id,
      adresse: rdv.adresse,
      dateRdv: rdv.dateRdv,
      consultant: rdv.consultant,
      dureeRdv: rdv.dureeRdv,
      typeRdv: TypeRdv.INTERNE,
      motif: rdv.motif,
      rdvStep: rdv.rdvStep,
      structureVeterinaire: rdv.structureVeterinaire,
    };
  }

  private static mapRdvDomicileToRdvPlanning(rdv: RdvDomicile): RdvPlanning {
    return {
      id: rdv.id,
      adresse: rdv.adresse,
      dateRdv: rdv.dateConsultation,
      dateSouhaitee: rdv.dateSouhaitee,
      consultant: rdv.consultantAssigne,
      dateCreneauRappel: rdv.dateCreneauRappel,
      motif: rdv.motif,
      dureeRdv: rdv.dureeRdv,
      acte: rdv.acte,
      typeRdv: TypeRdv.DOMICILE,
      animaux: rdv.animaux,
      structureVeterinaire: rdv.structureVeterinaire,
      client: rdv.client,
      rdvStep: rdv.rdvStep,
      previousStep: rdv.previousStep,
      infosVerifieesAsv: rdv.infosVerifieesAsv,
      allCrFinalises: RdvService.hasAllCRFinalises(rdv),
      interactions: rdv.interactions,
      dateDemande: rdv.dateDemande,
    };
  }

  public static hasAllCRFinalises(rdv: RdvDomicile) {
    return rdv.prestations?.length > 0 && rdv.prestations.every(p => p.compteRendu && p.compteRendu?.etat !== EtatCompteRenduEnum.EN_COURS);
  }

  findGroupedRdv(request: FindRdvRequest, typeRdv: TypeRdv) {
    return of(typeRdv === TypeRdv.INTERNE).pipe(
      switchMap(interne => {
        if (interne) {
          return this.rdvInterneService.findGroupedRdv(request);
        } else {
          return this.rdvDomicileService.findGroupedRdv(request);
        }
      }),
    );
  }

  updateRdv(rdv: RdvDomicile | RdvInterne) {
    return of(RdvService.isRdvInterne(rdv)).pipe(
      switchMap(interne => {
        if (interne) {
          return this.rdvInterneService.updateRdv(rdv as RdvInterne);
        } else {
          return this.rdvDomicileService.updateRdv(rdv as RdvDomicile);
        }
      }),
    );
  }

  creationRdv(rdv: CreationRdvPayloadType | CreationRdvInternePayloadType) {
    return of(RdvService.isRdvInterne(rdv)).pipe(
      switchMap(interne => {
        if (interne) {
          return this.rdvInterneService.creationRdv(rdv as RdvInterne);
        } else {
          return this.rdvDomicileService.creationRdv(rdv as RdvDomicile);
        }
      }),
    );
  }

  updateDateRdv(rdv: RdvPlanning, newDate: Date) {
    return of(rdv.typeRdv === TypeRdv.INTERNE).pipe(
      switchMap(interne => {
        if (interne) {
          return this.rdvInterneService.updateDateRdv(rdv.id, newDate);
        } else {
          return this.rdvDomicileService.updateDateConsultation(rdv.id, newDate);
        }
      }),
    );
  }

  updateConsultant(rdv: RdvPlanning, newConsultantId: string) {
    return of(rdv.typeRdv === TypeRdv.INTERNE).pipe(
      switchMap(interne => {
        if (interne) {
          return this.rdvInterneService.updateConsultant(rdv.id, newConsultantId);
        } else {
          return this.rdvDomicileService.updateConsultant(rdv.id, newConsultantId);
        }
      }),
    );
  }
}
