import { OrderDirectionEnum } from './rdv.model';

export enum UserRole {
  ROLE_CLIENT = 'ROLE_CLIENT',
  ROLE_VETERINAIRE = 'ROLE_VETERINAIRE',
  ROLE_BACKOFFICE = 'ROLE_BACKOFFICE',
  ROLE_TECHNIQUE = 'ROLE_TECHNIQUE',
  ROLE_ADMIN = 'ROLE_ADMIN',
}

export interface Adresse {
  adresse?: string;
  codePostal: string;
  codeInsee?: string;
  ville?: string;
  detail?: string;
  codeCentravet?: string;
  codeAdresseCentravet?: string;
}

export interface User {
  id: string;
  idVetophonie?: string;
  mail: string;
  mail2?: string;
  mailWorkplace?: string;
  createDateTime: Date;
  updateDateTime: Date;
  username: string;
  role: UserRole;
  nom?: string;
  nom2?: string;
  prenom?: string;
  prenom2?: string;
  telephone?: number;
  telephone2?: number;
  pictureName?: string;
  enabled: boolean;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  adresse?: Adresse;
  groupe: Groupe;
  numeroOrdre?: string;
  preferences?: UtilisateurPreferences;
  actif?: boolean;
  verifie?: boolean;
  detail?: string;
}

export class VilleForm {
  constructor(
    public code: string,
    public nom: string,
  ) {}

  toString() {
    return this.nom;
  }
}

export interface UtilisateurPreferences {
  id?: string;
  utilisateurId: string;
  gmapWaze?: 'gmap' | 'waze';
  notifAppVeto: boolean;
  notifMailVeto: boolean;
  notifSmsVeto: boolean;
  notifWorkplaceVeto: boolean;
  notifVetoNbJoursModifRdvPourNotif: number;
}

export interface Groupe {
  id?: string;
  codeGroupe: string;
  nom?: string;
}

export interface UserDevice {
  id: string;
  deviceId: string;
  utilisateur: User;
  typeDevice: string;
}

export interface UserResponse {
  status: string;
  user: User;
}

export interface UserWithDeviceId {
  user: User;
  deviceId: string;
}

export interface SendUserDevice {
  deviceId: string;
  utilisateur: {
    id: string;
  };
}

export interface Rappel {
  id?: string;
  type: TypeRappel;
  channels?: ChannelRappel[];
  dateRappel?: Date;
  dateEnvoiRappel?: Date;
  description?: string;
  animalId?: string;
  userId?: string;
  rdvId?: string;
}

export interface RappelRequest {
  envoye?: boolean;
  type?: TypeRappel;
  dateMin?: string;
  dateMax?: string;
  animalId?: string;
  userId?: string;
  rdvId?: string;
  order?: OrderDirectionEnum;
}

export enum TypeRappel {
  CONDOLEANCE = 'CONDOLEANCE',
  VACCIN = 'VACCIN',
  RAPPEL_CLIENT = 'RAPPEL_CLIENT',
  PRISE_NOUVELLES = 'PRISE_NOUVELLES',
}

export enum ChannelRappel {
  MAIL = 'MAIL',
  SMS = 'SMS',
  MIM = 'MIM',
  WORKCHAT = 'WORKCHAT',
}

export enum TypeVaccin {
  CHPPiL4 = 'CHPPiL4',
  CHPPiL4R = 'CHPPiL4R',
  PiL4 = 'PiL4',
  PiL4R = 'PiL4R',
  CHPPiL4_KC = 'CHPPiL4-KC',
  CHPPiL4R_KC = 'CHPPiL4R-KC',
  PiL4_KC = 'PiL4-KC',
  PiL4R_KC = 'PiL4R-KC',
  TC = 'TC',
  TCL = 'TCL',
  TCLR = 'TCLR',
  Canileish = 'Canileish',
  Letifend = 'Letifend',
  Pirodog = 'Pirodog',
  Rage = 'Rage',
  Myxo_VHD = 'Myxo+VHD',
  Filavac = 'Filavac',
  Toux_de_chenil = 'Toux de Chenil',
}
