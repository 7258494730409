import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { User } from '../../../../models/user.model';
import { UserService } from '@core/services/user/user.service';
import { ToastController } from '@ionic/angular';
import { from, map, of, switchMap } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'forgot-password',
  templateUrl: './forgot-password.page.html',
  styleUrls: ['./forgot-password.page.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ForgotPasswordPage {
  resetPasswordForm = this.formBuilder.group({
    email: ['', [Validators.required, Validators.email]],
    user: [null],
  });

  users?: User[];
  isSubmitting: boolean = false;

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly userService: UserService,
    private readonly toastController: ToastController,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {}

  submit() {
    this.isSubmitting = true;
    const email = this.resetPasswordForm.value.email?.toLowerCase();
    if (email && !this.resetPasswordForm.value.user) {
      this.userService
        .findByEmail(email)
        .pipe(
          tap(users => {
            this.users = users;
            this.changeDetectorRef.markForCheck();
          }),
          switchMap(users => {
            if (users.length === 1) {
              return this.userService.resetPassword(users[0].id).pipe(
                switchMap(() =>
                  from(
                    this.toastController.create({
                      header: 'Mail envoyé',
                      duration: 5000,
                      message: `Un lien pour réinitialiser votre mot de passe vous a été envoyé par mail`,
                      position: 'bottom',
                    }),
                  ),
                ),
                map(toast => toast.present()),
              );
            } else {
              return of({});
            }
          }),
        )
        .subscribe(() => (this.isSubmitting = false));
    } else if (this.resetPasswordForm.value.user) {
      this.userService
        // @ts-ignore
        .resetPassword(this.resetPasswordForm.value.user.id)
        .pipe(
          switchMap(() =>
            from(
              this.toastController.create({
                header: 'Mail envoyé',
                duration: 5000,
                message: `Un lien pour réinitialiser votre mot de passe vous a été envoyé par mail`,
                position: 'bottom',
              }),
            ),
          ),
          map(toast => toast.present()),
        )
        .subscribe(() => (this.isSubmitting = false));
    }
  }
}
