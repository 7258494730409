import { Action, createReducer, on } from '@ngrx/store';
import { EtatFacturationEnum, EtatFactureEnum, Facturation, Prestation } from '../../../../models/rdv.model';
import { PrestationAction } from '@core/store/actions/prestation.action';
import { ProduitActions } from '@core/store/actions/produit.actions';
import { cloneDeep, isNil } from 'lodash';
import { ProduitDelivre, ProduitPrescrit } from '../../../../models/produit.model';
import { RdvDomicileAction } from '@core/store/actions/rdv-domicile.action';

export interface PrestationState {
  readonly activePrestation: Prestation | null;
}

export const initialState: PrestationState = {
  activePrestation: null,
};

function addOrSave(savedObj: any, objList?: any[], listIdx?: number) {
  if (isNil(objList)) {
    return [savedObj];
  }
  if (!objList.map(existingObj => existingObj.id).includes(savedObj.id)) {
    if (listIdx === undefined) {
      return [...objList, savedObj];
    } else {
      const newObjList = [...objList];
      newObjList[listIdx] = savedObj;

      return newObjList;
    }
  } else {
    return objList.map(existingObj => (existingObj.id === savedObj.id ? savedObj : existingObj));
  }
}

export const prestationReducer = createReducer(
  initialState,

  on(
    PrestationAction.accessPrestationSuccess,
    (state, { prestation }): PrestationState => ({
      ...state,
      activePrestation: prestation,
    }),
  ),
  on(
    PrestationAction.resetactiveprestation,
    (state): PrestationState => ({
      ...state,
      activePrestation: null,
    }),
  ),
  on(
    PrestationAction.saveprestationsuccess,
    (state, { prestation: updatedPrestation }): PrestationState => ({
      ...state,
      activePrestation: updatedPrestation,
    }),
  ),
  on(
    PrestationAction.createcrsuccess,
    (state, { compteRendu }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        compteRendu,
      },
    }),
  ),
  on(
    PrestationAction.markfacturepayeesuccess,
    (state, { id }): PrestationState => ({
      ...state,
      activePrestation: state.activePrestation
        ? {
            ...state.activePrestation!,
            factures: state.activePrestation!.factures!.map(f => (f.id === id ? { ...f, etat: EtatFactureEnum.PAYEE } : f)),
            facturations: state.activePrestation!.facturations!.map(f => (f.idFacture === id ? { ...f, etat: EtatFacturationEnum.PAYEE } : f)),
          }
        : null,
    }),
  ),
  on(
    ProduitActions.saveordonnancessuccess,
    (state, { ordonnances }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        ordonnances,
      },
    }),
  ),
  on(
    RdvDomicileAction.updateAnimalSuccess,
    (state, { updatedAnimal }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        animal: updatedAnimal,
      },
    }),
  ),
  on(
    ProduitActions.saveordonnancesuccess,
    (state, { ordonnance }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        ordonnances: addOrSave(ordonnance, state.activePrestation!.ordonnances),
      },
    }),
  ),
  on(
    ProduitActions.deleteordonnancesuccess,
    (state, { idOrdonnance }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        ordonnances: state.activePrestation!.ordonnances!.filter(ordo => ordo.id !== idOrdonnance),
      },
    }),
  ),
  on(
    ProduitActions.deletefacturationsuccess,
    (state, { id }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations: state.activePrestation!.facturations!.filter(facturation => facturation.id !== id),
      },
    }),
  ),
  on(
    ProduitActions.generatefacturationsuccess,
    (state, { facturations }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations: mergeFacturations(state.activePrestation!.facturations!, facturations),
      },
    }),
  ),
  on(
    ProduitActions.savefacturationsuccess,
    (state, { facturation, idxFacturation }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations: addOrSave(facturation, state.activePrestation!.facturations, idxFacturation),
      },
    }),
  ),
  on(
    ProduitActions.updatefacturationstatesuccess,
    (state, { facturation, idxFacturation }): PrestationState => ({
      ...state,
      activePrestation: state.activePrestation
        ? {
            ...state.activePrestation,
            facturations: addOrSave(facturation, state.activePrestation.facturations, idxFacturation),
          }
        : null,
    }),
  ),
  on(
    ProduitActions.savefacturationssuccess,
    (state, { facturations }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        facturations,
      },
    }),
  ),
  on(
    PrestationAction.updatecommentaireinternesuccess,
    (state, { commentaireInterne }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        commentaireInterne,
      },
    }),
  ),
  on(
    PrestationAction.demandeconsentementsuccess,
    (state, { consentement }): PrestationState => ({
      ...state,
      activePrestation: {
        ...state.activePrestation!,
        consentements: addOrSave(consentement, state.activePrestation!.consentements),
      },
    }),
  ),
);

function areProduitsEqual(p1: ProduitDelivre | ProduitPrescrit, p2: ProduitDelivre | ProduitPrescrit): boolean {
  return p1.numLot === p2.numLot && (p1.produit?.id === p2.produit?.id || p1.nom === p2.nom);
}

/**
 * @param existingFacturations les factus existantes
 * @param facturations les factus générées par les ordonnances
 */
function mergeFacturations(existingFacturations: Facturation[], facturations: Facturation[]): Facturation[] {
  const facturationsToReturn: Facturation[] = existingFacturations
    ? existingFacturations.filter(f => [EtatFacturationEnum.TERMINEE, EtatFacturationEnum.PAYEE, EtatFacturationEnum.FINALISEE].includes(f.etat))
    : []; // si on a des factu payées on y touche pas
  const filteredExistingFacturations: Facturation[] = existingFacturations
    ? existingFacturations.filter(f => ![EtatFacturationEnum.TERMINEE, EtatFacturationEnum.PAYEE, EtatFacturationEnum.FINALISEE].includes(f.etat))
    : []; // on va itérer sur les factu non payées existantes
  const produitsPayes = facturationsToReturn.flatMap(f => (f.produitsDelivres ? f.produitsDelivres : []));
  if (facturations.length > 0) {
    if (!filteredExistingFacturations || filteredExistingFacturations.length <= 0) {
      // si la liste des factus existantes non payées est vide, on crée une nouvelle factu qui regroupe toutes les ordos
      const newFacturation: Facturation = {
        etat: EtatFacturationEnum.EN_COURS,
        idOrdonnances: facturations.flatMap(factu => factu.idOrdonnances!),
        produitsDelivres: facturations.flatMap(factu => factu.produitsDelivres!).filter(p => produitsPayes.findIndex(p2 => areProduitsEqual(p, p2)) < 0),
      };

      if (newFacturation.produitsDelivres && newFacturation.produitsDelivres.length > 0) {
        facturationsToReturn.push(newFacturation);
      }
    } else {
      // si on a une ou plusieurs factus existantes non payées
      for (let i = 0; i < filteredExistingFacturations.length; i++) {
        const existingFacturation = filteredExistingFacturations[i];
        const produits: ProduitDelivre[] = [];
        const newFacturation: Facturation = cloneDeep(existingFacturation);
        for (let facturation of facturations) {
          if (facturation.idOrdonnances?.[0] && existingFacturation.idOrdonnances?.includes(facturation.idOrdonnances?.[0])) {
            if (facturation.produitsDelivres) {
              facturation.produitsDelivres
                .filter(p => produitsPayes.findIndex(p2 => areProduitsEqual(p, p2)) < 0)
                .forEach(produit => {
                  let found: boolean = false;
                  if (existingFacturation.produitsDelivres) {
                    for (let existingProduit of existingFacturation.produitsDelivres) {
                      if (areProduitsEqual(existingProduit, produit)) {
                        found = true;
                        produits.push({ ...existingProduit });
                        break;
                      }
                    }
                  }
                  if (!found) {
                    produits.push(produit);
                  }
                });
            }
          } else {
            if (i === 0) {
              if (facturation.produitsDelivres) {
                produits.push(...facturation.produitsDelivres);
              }
              if (!existingFacturation.idOrdonnances?.length && existingFacturation.produitsDelivres) {
                produits.push(...existingFacturation.produitsDelivres);
              }
              if (facturation.idOrdonnances?.[0]) {
                if (newFacturation.idOrdonnances && newFacturation.idOrdonnances?.length > 0) {
                  newFacturation.idOrdonnances = [...newFacturation.idOrdonnances, facturation.idOrdonnances?.[0]];
                } else {
                  newFacturation.idOrdonnances = facturation.idOrdonnances;
                }
              }
            }
          }
        }
        if (newFacturation.produitsDelivres?.length !== produits.length) {
          newFacturation.etat = EtatFacturationEnum.EN_COURS;
        }
        newFacturation.produitsDelivres = produits;
        if (newFacturation.produitsDelivres?.length > 0 || (newFacturation.actesRealises && newFacturation.actesRealises.length > 0)) {
          facturationsToReturn.push(newFacturation);
        }
      }
    }
  } else {
    return existingFacturations;
  }

  return facturationsToReturn;
}

export function reducer(state: PrestationState, action: Action): PrestationState {
  return prestationReducer(state, action);
}
