import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Acte } from '../../../../models/rdv.model';
import { EMPTY, map, Observable } from 'rxjs';
import { Espece, Race } from '../../../../models/animal.model';
import { Produit, SearchProduitRequest } from '../../../../models/produit.model';
import { Page } from '../../../../models/page.model';

@Injectable({
  providedIn: 'root',
})
export class ReferenceService {
  constructor(private readonly httpApiService: HttpApiService) {}

  findActesByIdStructure(idStructure: string): Observable<Acte[]> {
    return this.httpApiService.get(`api/back_office/reference/actes/${idStructure}`);
  }

  getEspeces(): Observable<Espece[]> {
    return this.httpApiService.get<Espece[]>('api/especes').pipe(map(data => data));
  }

  getAnimalRaces(espece: string | undefined): Observable<Race[]> {
    return this.httpApiService.get<Race[]>(`api-particuliers/animal/${espece}/races`).pipe(map(raceData => raceData));
  }

  public getAllProduits(): Observable<Produit[]> {
    return this.httpApiService.get(`api/back_office/produit/`);
  }

  public searchProduits(request: SearchProduitRequest): Observable<Page<Produit>> {
    if (!request) {
      return EMPTY;
    }
    if (request.page === undefined) {
      request.page = 0;
    }
    if (request.offset === undefined) {
      request.offset = 0;
    }
    if (request.size === undefined) {
      request.size = 99999;
    }

    return this.httpApiService.post(`api/back_office/produit/search`, request);
  }
}
