export const environment = {
  refreshChatTime: 180000,
  refreshVetoDispoTime: 60000,
  delayGeoApi: 200,
  production: true,
  enableDashBoard: true,
  enableAdmin: true,
  enableClient: true,
  enableClientForAdmin: true,
  enableDashBoardForAdmin: true,
  API_URL: 'https://backoffice.drmilou.fr',
  vapidKey: 'BP0wQFQRXGRSm7JOB6UiTNRxIV2nvpFPmf9MLU5sV5S1VdeF2YpeC8ZRjIPsWWJOHyag66Degb8m_AiC5F999vQ',
  firebase: {
    apiKey: 'AIzaSyAc9vk5ZVzPCOq5m0nqLf-g8BpUslIS76M',
    authDomain: 'dr-milou-app.firebaseapp.com',
    projectId: 'dr-milou-app',
    storageBucket: 'dr-milou-app.appspot.com',
    messagingSenderId: '389122671721',
    appId: '1:389122671721:web:d6d42c0ebbff3f50008ef8',
    measurementId: 'G-ZN9R1H4286',
  },
};
