import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Observable } from 'rxjs';
import { ModePaiementEnum, Paiement } from '../../../../models/paiement.model';

@Injectable({
  providedIn: 'root',
})
export class PaiementService {
  constructor(private readonly httpApiService: HttpApiService) {}

  ajoutPaiement(idRdv: string, montant: number, modePaiement: ModePaiementEnum): Observable<Paiement> {
    return this.httpApiService.post<Paiement>(`api/back_office/paiement/rdvDomicile/${idRdv}`, { montant, modePaiement });
  }

  deletePaiement(idRdv: string, idPaiement: string) {
    return this.httpApiService.delete(`api/back_office/paiement/rdvDomicile/${idRdv}/${idPaiement}`);
  }

  getPaiementsByIdRdvDomicile(idRdv: string): Observable<Paiement[]> {
    return this.httpApiService.get(`api/back_office/paiement/rdvDomicile/${idRdv}`);
  }

  getRestePaiement(idRdv: string): Observable<number> {
    return this.httpApiService.get(`api/back_office/paiement/rdvDomicile/${idRdv}/reste`);
  }

  relancePaiement(idPaiement: string, idRdv: string) {
    return this.httpApiService.post<void>(`api/back_office/paiement/rdvDomicile/${idRdv}/${idPaiement}/renvoi`);
  }
}
