import { Injectable } from '@angular/core';
import { HttpApiService } from '@core/services/http-api/http-api.service';
import { Ordonnance } from '../../../../models/ordonnance.model';
import { EtatFacturationEnum, Facturation } from '../../../../models/rdv.model';
import { Observable, of } from 'rxjs';
import { Produit } from '../../../../models/produit.model';

@Injectable({
  providedIn: 'root',
})
export class FacturationService {
  constructor(private readonly httpApiService: HttpApiService) {}

  generateFacturations(ordonnances: Ordonnance[]): Observable<Facturation[]> {
    const ordonnancesTermineesAvecProduitsDelivres = ordonnances.filter(ordo => ordo.produitPrescrits?.filter(produit => produit.delivre)?.length > 0);

    return of(
      ordonnancesTermineesAvecProduitsDelivres.map((ordo: Ordonnance, idx: number) => ({
        etat: EtatFacturationEnum.EN_COURS,
        idx,
        idOrdonnances: [ordo.id],
        produitsDelivres: ordo.produitPrescrits
          .filter(produit => produit.delivre)
          .map(produitPrescrit => ({
            produit: produitPrescrit.produit,
            quantiteUvc: produitPrescrit.quantiteUvp,
            numLot: produitPrescrit.numLot,
            remise: 0,
            nom: produitPrescrit.produit?.nom ? FacturationService.getProduitName(produitPrescrit.produit) : produitPrescrit.nom!,
            tarifHT: produitPrescrit.produit?.tarifVenteHT ? produitPrescrit.produit.tarifVenteHT : 0,
          })),
      })),
    );
  }

  deleteFacturation(id: string): Observable<void> {
    return this.httpApiService.delete(`api/back_office/facturation/${id}`);
  }

  saveFacturation(facturation: Facturation, idPrestation: string): Observable<Facturation> {
    return this.httpApiService.post<Facturation>(`api/back_office/facturation/${idPrestation}`, facturation);
  }

  saveFacturations(facturations: Facturation[], idPrestation: string) {
    return this.httpApiService.post<Facturation[]>(`api/back_office/facturation/${idPrestation}/batch`, facturations);
  }

  envoyerFactureParMail(idFacturation: string, email: string): Observable<void> {
    return this.httpApiService.post<void>(`api/back_office/facturation/${idFacturation}/envoyerFactureParMail`, email);
  }

  updateFacturationState(idPrestation: string, idFacturation: string, etat: EtatFacturationEnum) {
    return this.httpApiService.post<Facturation>(`api/back_office/facturation/${idPrestation}/${idFacturation}/etat/${etat}`);
  }

  static getProduitName(p: Produit): string {
    return `${p.nom} ${p.unite || p.packaging ? '(' + (p.unite ? p.unite.toLowerCase() : p.packaging) + ')' : ''}`;
  }
}
