import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable, Injector } from '@angular/core';

import { EMPTY, lastValueFrom, throwError } from 'rxjs';

import { ApiErrorHandlerService } from './api-error-handler.service';
import { ToastController } from '@ionic/angular';
import { Action, Context, TypeLog } from '../../../../models/log.model';
import { LogService } from '@core/services/log/log.service';
import { Store } from '@ngrx/store';
import { UserApiActions } from '@core/store/actions/user.actions';
import { selectIsUserNotAuthenticated } from '@core/store/selector/session.selectors';
import { ApiError } from '../../../../models/api-error.model';

/** Global error handler */
@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandlerService implements ErrorHandler {
  // race condition de leaflet qui vérifie pas si la map existe tjs lors de l'appel à fitbounds, setzoom etc
  private ignoredErrorStr: string[] = [
    'leaflet',
    'this._map is',
    'this._renderer',
    'el is undefined',
    'removeLayer',
    'addLayer',
    'o is undefined',
    's is undefined',
    'ExpressionChangedAfterItHasBeenCheckedError',
  ];

  private get logService() {
    return this.injector.get(LogService);
  }

  private get store() {
    return this.injector.get(Store);
  }

  constructor(
    private readonly apiErrorHandlerService: ApiErrorHandlerService,
    private readonly toastController: ToastController,
    private readonly injector: Injector,
  ) {}

  handleError(error: Error | HttpErrorResponse, showNotif = true, rethrowError = true) {
    if (error instanceof HttpErrorResponse) {
      return this.handleApiError(error, showNotif, rethrowError);
    }

    if (this.ignoredErrorStr.some(str => error.message.includes(str))) {
      return EMPTY;
    }

    console.log('ERREUR');
    this.logService.log(Context.HANDLE_ERROR, Action.UNKNOWN, TypeLog.ERROR, error);
    this.showToaster(error.message, error.message, 'warning');

    console.error(error);

    return throwError(() => error);
  }

  private async handleApiError(error: HttpErrorResponse, showNotif: boolean, rethrowError: boolean): Promise<ApiError | null> {
    console.log('ERREUR !!!!!!');
    const apiError = this.apiErrorHandlerService.process(error);

    if (apiError && showNotif) {
      console.log(apiError.details ? apiError.details.message : apiError.message, undefined);
    }

    if (apiError && (apiError.message === 'NOT_ACCEPTABLE' || apiError.message === 'BAD_REQUEST')) {
      await this.showToaster(apiError.details.message, apiError.message, 'danger');
    } else if (apiError && apiError.message === 'FORBIDDEN') {
      await this.showToaster("Vous n'est pas habilité a effectué ce geste !", apiError.message, 'danger');
    } else if (apiError && apiError.message === 'INTERNAL_SERVER_ERROR') {
      // this.store.dispatch(UserApiActions.logout());
      await this.showToaster('Une erreur est arrivée, merci de contacter le service technique !', apiError.message, 'danger');
    } else if (apiError && apiError.message === 'UNAUTHORIZED') {
      const isAuthenticated = await lastValueFrom(this.store.select(selectIsUserNotAuthenticated));

      if (isAuthenticated) {
        this.store.dispatch(UserApiActions.logout());
        await this.showToaster('Une erreur est arrivée, autorisation manquante', apiError.message, 'danger');
      }
    }
    if (rethrowError) {
      return apiError;
    } else {
      return Promise.reject();
    }
  }

  private async showToaster(message: string, error: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 10000,
      position: 'bottom',
      color: color,
      icon: 'warning',
      buttons: [
        {
          text: '',
          role: 'cancel',
          icon: 'close',
        },
      ],
    });

    if (error === 'NOT_ACCEPTABLE') {
      toast.onDidDismiss().then(() => window.location.reload());
    }

    toast.present();
  }
}
