import { Injectable } from '@angular/core';
import isToday from 'date-fns/isToday';
import isTomorrow from 'date-fns/isTomorrow';
import isYesterday from 'date-fns/isYesterday';
import { DatePipe } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class SharedService {
  constructor(private readonly datePipe: DatePipe) {}

  static groupBy<T, K>(array: T[], keyExtractor: (item: T) => K): Map<K, T[]> {
    return array.reduce((map, item) => {
      const key = keyExtractor(item);
      const group = map.get(key);
      if (!group) {
        map.set(key, [item]);
      } else {
        group.push(item);
      }

      return map;
    }, new Map<K, T[]>());
  }

  formatDateRdv(date: string | Date): string {
    const dataDate = new Date(date);
    let displayDate: string | null = '';
    if (dataDate) {
      if (isToday(dataDate)) {
        displayDate = "Aujourd'hui à ";
      } else if (isTomorrow(dataDate)) {
        displayDate = 'Demain à ';
      } else if (isYesterday(dataDate)) {
        displayDate = 'Hier à ';
      } else {
        displayDate = this.datePipe.transform(dataDate, 'shortDate') + ' - ';
      }
    }

    return displayDate ? displayDate + this.datePipe.transform(dataDate, 'shortTime') : 'Une erreur est survenue';
  }
}
