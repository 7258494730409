import { Action, createReducer, on } from '@ngrx/store';
import { UserWithAnimal } from 'app/models/client.model';
import { clientActions } from '../actions/clients.actions';
import { Animal } from 'app/models/animal.model';
import { Client, Facture, RdvDomicile } from 'app/models/rdv.model';
import { Poids } from 'app/models/compte-rendu.model';
import { Page } from '../../../../models/page.model';

export interface clientsState {
  clientList: Page<UserWithAnimal> | null;
  activeClientSearch: Client | null;
  animalClientList?: Animal[];
  activeAnimal?: Animal | undefined;
  facturesList?: Facture[];
  historiqueList?: RdvDomicile[];
  listPoids: Poids[];
  clientScroll: UserWithAnimal[];
}

export const initialState: clientsState = {
  clientList: null,
  activeClientSearch: null,
  listPoids: [],
  clientScroll: [],
};

export const clientsReducer = createReducer(
  initialState,

  on(
    clientActions.searchClientSuccess,
    (state, { results }): clientsState => ({
      ...state,
      clientScroll: results.elements,
      clientList: results,
    }),
  ),

  on(
    clientActions.searchClientScrollSuccess,
    (state, { results }): clientsState => ({
      ...state,
      clientScroll: [...state.clientScroll, ...results.elements],
      clientList: results,
    }),
  ),
  on(
    clientActions.resetSearchState,
    (state): clientsState => ({
      ...state,
      clientScroll: initialState.clientScroll,
      clientList: initialState.clientList,
    }),
  ),
  on(
    clientActions.getClientsInfosSuccess,
    (state, { infosClient }): clientsState => ({
      ...state,
      activeClientSearch: infosClient,
    }),
  ),
  on(
    clientActions.getHistoriqueSuccess,
    (state, { rdvData }): clientsState => ({
      ...state,
      historiqueList: rdvData.elements,
    }),
  ),
  on(
    clientActions.getAnimalListClientSuccess,
    (state, { animalClientList }): clientsState => ({
      ...state,
      animalClientList: animalClientList,
    }),
  ),
  on(clientActions.setActiveFicheAnimalSuccess, (state, { animal }): clientsState => ({ ...state, activeAnimal: animal })),
  on(
    clientActions.resetStateClient,
    (state): clientsState => ({
      ...state,
      activeClientSearch: initialState.activeClientSearch,
      activeAnimal: undefined,
      historiqueList: [],
    }),
  ),

  on(clientActions.getPoidsSuccess, (state, { poids }): clientsState => ({ ...state, listPoids: poids })),
  on(clientActions.resetPoids, (state): clientsState => ({ ...state, listPoids: [] })),
  on(
    clientActions.editFicheClientSuccess,
    (state, { client }): clientsState => ({ ...state, activeClientSearch: client, clientScroll: replaceEditClient(client, state) }),
  ),
);

export function replaceEditClient(client: Client, initialState: clientsState): UserWithAnimal[] {
  let newUserWithAnimalsList: UserWithAnimal[] = JSON.parse(JSON.stringify(initialState.clientScroll));
  newUserWithAnimalsList?.forEach(c => {
    if (c.id === client.id) {
      Object.assign(c, client);
    }
  });

  return newUserWithAnimalsList;
}

export function reducer(state: clientsState, action: Action): clientsState {
  return clientsReducer(state, action);
}
