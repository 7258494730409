import { Injectable } from '@angular/core';

import { clientActions } from '../actions/clients.actions';
import { map, switchMap } from 'rxjs';
import { FicheClientService } from 'app/modules/fiche-client/services/fiche-client.service';

import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Animal } from 'app/models/animal.model';
import { RdvDomicileService } from '@core/services/consultations/rdv-domicile.service';
import { FindRdvRequest, RdvState } from 'app/models/rdv.model';
import { PoidsService } from 'app/modules/shared/services/poids.service';
import { Poids } from 'app/models/compte-rendu.model';
import { AnimalService } from '../../../rdv/wizard/services/animal.service';

@Injectable()
export class clientsEffects {
  constructor(
    private readonly actions$: Actions,
    private readonly clientService: FicheClientService,
    private readonly animalService: AnimalService,
    private readonly rdvService: RdvDomicileService,
    private readonly poidsService: PoidsService,
  ) {}

  searchClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.searchClient),
      switchMap(action => {
        return this.clientService.getClient(action.input, 1, 0).pipe(
          map(data => {
            return clientActions.searchClientSuccess({ results: data });
          }),
        );
      }),
    );
  });

  scrollClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.searchClientScroll),
      switchMap(action =>
        this.clientService.getClient(action.input, action.pageNumber, action.offset).pipe(
          map(data => {
            return clientActions.searchClientScrollSuccess({ results: data });
          }),
        ),
      ),
    );
  });

  getClientInfos$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.getClientInfos),
      switchMap(action => this.clientService.getInfosClient(action.idClient).pipe(map(data => clientActions.getClientsInfosSuccess({ infosClient: data })))),
    );
  });

  getClientHistorique$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.getClientInfos),
      switchMap(action =>
        this.rdvService.findAllRdv(this.buildRequest(action.idClient)).pipe(map(data => clientActions.getHistoriqueSuccess({ rdvData: data }))),
      ),
    );
  });

  editClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.editFicheClient),
      switchMap(action => {
        return this.rdvService.updateClient(action.client).pipe(
          map(updatedClient => {
            return clientActions.editFicheClientSuccess({ client: updatedClient });
          }),
        );
      }),
    );
  });

  getAnimalListClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.getAnimalListClient),
      switchMap(action => {
        return this.animalService.getUserAnimals(action.idClient).pipe(
          map((animalList: Animal[]) => {
            return clientActions.getAnimalListClientSuccess({ animalClientList: animalList });
          }),
        );
      }),
    );
  });

  setActiveAnimalClient$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.setActiveFicheAnimal),
      switchMap(action => {
        return this.animalService.getUserAnimals(action.idClient).pipe(
          map((animalList: Animal[]) => {
            const activeAnimal = animalList.filter(animal => animal.id === action.idAnimal)[0];

            return clientActions.setActiveFicheAnimalSuccess({ animal: activeAnimal });
          }),
        );
      }),
    );
  });

  editFicheAnimal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.editFicheAnimal),
      switchMap(action =>
        this.animalService
          .editAnimal(action.animal)
          .pipe(map(() => clientActions.setActiveFicheAnimal({ idAnimal: action.animal.id!, idClient: action.animal.user!.id }))),
      ),
    );
  });

  getPoids$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.getPoids),
      switchMap(action =>
        this.poidsService.getPoids(action.idAnimal).pipe(
          map((poids: Poids[]) => {
            const newPoids = poids.sort((a: Poids, b: Poids) => {
              const date1 = new Date(a.datePesee!);
              const date2 = new Date(b.datePesee!);

              return date1.getTime() - date2.getTime();
            });

            return clientActions.getPoidsSuccess({ poids: newPoids.reverse() });
          }),
        ),
      ),
    );
  });

  updatePoids$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.updatePoids),
      switchMap(action => this.poidsService.updatePoids(action.newPoids).pipe(map(() => clientActions.getPoids({ idAnimal: action.newPoids.idAnimal })))),
    );
  });

  deletePoids$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(clientActions.deletePoids),
      switchMap(action => this.poidsService.deletePoids(action.newPoids).pipe(map(() => clientActions.getPoids({ idAnimal: action.newPoids.idAnimal })))),
    );
  });

  private buildRequest(idClient: string): FindRdvRequest {
    return {
      clientId: idClient,
      withPrestations: true,
      notRdvStep: [RdvState.ABANDONNEE],
    };
  }
}
