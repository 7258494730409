import { Injectable } from '@angular/core';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProduitActions } from '@core/store/actions/produit.actions';
import { OrdonnanceService } from '../../../rdv/wizard/services/ordonnance.service';
import { Ordonnance } from '../../../../models/ordonnance.model';
import { Devis, Facturation } from '../../../../models/rdv.model';
import { FacturationService } from '../../../rdv/wizard/services/facturation.service';
import { DevisService } from '../../../rdv/wizard/services/devis.service';
import { SavingService } from '@core/services/saving/saving.service';
import { EMPTY } from 'rxjs';

@Injectable()
export class ProduitEffects {
  saveordonnances$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.saveordonnances),
      tap(() => this.savingService.increment()),
      switchMap(action => {
        return this.ordonnanceService.saveOrdonnances(action.ordonnances, action.idPrestation).pipe(
          map((ordonnances: Ordonnance[]) => {
            return ProduitActions.saveordonnancessuccess({ ordonnances, idPrestation: action.idPrestation });
          }),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        );
      }),
    );
  });

  saveordonnancessuccess$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.saveordonnancessuccess),
      map(action => ProduitActions.generatefacturation({ ordonnances: action.ordonnances })),
      tap(() => {
        console.log('saveordonnancessuccess');
        this.savingService.decrement();
      }),
    );
  });
  saveordonnance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.saveordonnance),
      tap(() => this.savingService.increment()),
      switchMap(action => {
        return this.ordonnanceService.saveOrdonnance(action.ordonnance, action.idPrestation).pipe(
          map((ordonnance: Ordonnance) => {
            return ProduitActions.saveordonnancesuccess({ ordonnance, idPrestation: action.idPrestation });
          }),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        );
      }),
    );
  });

  saveordonnancesuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.saveordonnancesuccess),
        tap(() => {
          console.log('saveordonnancesuccess');
          this.savingService.decrement();
        }),
      );
    },
    { dispatch: false },
  );
  deleteordonnance$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.deleteordonnance),
      switchMap(action => {
        return this.ordonnanceService.deleteOrdonnance(action.idOrdonnance).pipe(
          map(() => {
            return ProduitActions.deleteordonnancesuccess({ idOrdonnance: action.idOrdonnance });
          }),
        );
      }),
    );
  });

  deleteordonnancesuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.deleteordonnancesuccess),
        tap(() => {
          console.log('deleteordonnancesuccess');
        }),
      );
    },
    { dispatch: false },
  );

  generatefacture$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.generatefacturation),
      switchMap(action => {
        return this.facturationService.generateFacturations(action.ordonnances).pipe(
          map((facturations: Facturation[]) => {
            return ProduitActions.generatefacturationsuccess({ facturations });
          }),
        );
      }),
    );
  });

  generatefacturesuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.generatefacturationsuccess),
        tap(() => {
          console.log('generatefacturesuccess');
        }),
      );
    },
    { dispatch: false },
  );

  deletefacturation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.deletefacturation),
      switchMap(action => {
        return this.facturationService.deleteFacturation(action.id).pipe(
          map(() => {
            return ProduitActions.deletefacturationsuccess({ id: action.id });
          }),
        );
      }),
    );
  });

  deletefacturationsuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.deletefacturationsuccess),
        tap(() => {
          console.log('deletefacturationsuccess');
        }),
      );
    },
    { dispatch: false },
  );

  savefacturation$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.savefacturation),
      switchMap(action => {
        return this.facturationService.saveFacturation(action.facturation, action.idPrestation).pipe(
          map((facturation: Facturation) => {
            return ProduitActions.savefacturationsuccess({ facturation, idPrestation: action.idPrestation, idxFacturation: action.idxFacturation });
          }),
        );
      }),
    );
  });

  savefacturationsuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.savefacturationsuccess),
        tap(() => {
          console.log('savefacturationsuccess');
        }),
      );
    },
    { dispatch: false },
  );
  updatefacturationstate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.updatefacturationstate),
      switchMap(action => {
        return this.facturationService.updateFacturationState(action.idPrestation, action.idFacturation, action.etat).pipe(
          map(facturation => {
            return ProduitActions.updatefacturationstatesuccess({
              idxFacturation: action.idxFacturation,
              facturation,
            });
          }),
        );
      }),
    );
  });

  updatefacturationstatesuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.updatefacturationstatesuccess),
        tap(() => {
          console.log('updatefacturationstatesuccess');
        }),
      );
    },
    { dispatch: false },
  );
  savefacturations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.savefacturations),
      tap(() => this.savingService.increment()),
      switchMap(action => {
        return this.facturationService.saveFacturations(action.facturations, action.idPrestation).pipe(
          map((facturations: Facturation[]) => {
            return ProduitActions.savefacturationssuccess({ facturations, idPrestation: action.idPrestation });
          }),
          catchError(() => {
            this.savingService.decrement();

            return EMPTY;
          }),
        );
      }),
    );
  });

  savefacturationssuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.savefacturationssuccess),
        tap(() => {
          this.savingService.decrement();
          console.log('savefacturationssuccess');
        }),
      );
    },
    { dispatch: false },
  );
  deletedevis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.deletedevis),
      switchMap(action => {
        return this.devisService.deleteDevis(action.id).pipe(
          map(() => {
            return ProduitActions.deletedevissuccess({ id: action.id });
          }),
        );
      }),
    );
  });

  deletedevissuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.deletedevissuccess),
        tap(() => {
          console.log('deletedevissuccess');
        }),
      );
    },
    { dispatch: false },
  );

  savedevis$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.savedevis),
      switchMap(action => {
        return this.devisService.saveDevis(action.devis).pipe(
          map((devis: Devis) => {
            return ProduitActions.savedevissuccess({ devis });
          }),
        );
      }),
    );
  });

  savedevissuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.savedevissuccess),
        tap(() => {
          console.log('savedevissuccess');
        }),
      );
    },
    { dispatch: false },
  );
  updatedevisstate$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.updatedevisstate),
      switchMap(action => {
        return this.devisService.updateDevisState(action.idDevis, action.etat).pipe(
          map(devis => {
            return ProduitActions.updatedevisstatesuccess({
              idxDevis: action.idxDevis,
              devis,
            });
          }),
        );
      }),
    );
  });

  updatedevisstatesuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.updatedevisstatesuccess),
        tap(() => {
          console.log('updatedevisstatesuccess');
        }),
      );
    },
    { dispatch: false },
  );
  savedevislist$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProduitActions.savedevislist),
      switchMap(action => {
        return this.devisService.saveDevisList(action.devisList).pipe(
          map((devisList: Devis[]) => {
            return ProduitActions.savedevislistsuccess({ devisList });
          }),
        );
      }),
    );
  });

  savedevislistsuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ProduitActions.savedevislistsuccess),
        tap(() => {
          console.log('savedevislistsuccess');
        }),
      );
    },
    { dispatch: false },
  );

  constructor(
    private readonly actions$: Actions,
    private readonly ordonnanceService: OrdonnanceService,
    private readonly facturationService: FacturationService,
    private readonly devisService: DevisService,
    private readonly savingService: SavingService,
  ) {}
}
